import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/post-list"
import IndexHeader from "../components/indexheader"
import Footer from "../components/footer"
import GlobalStyles from '../styles/globalstyles'
import Typography from '../styles/typography'
import { motion } from 'framer-motion'

const Index = ({ data, location }) => {

  const target = React.createRef();
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <div location={location} title={siteTitle}>
      <GlobalStyles />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, delay: 0.1 }}
      >
        <Typography />

      <Seo title="This is Just A Dream." />
      <IndexHeader/>
      <div className="global-wrapper" >
        <main className="main-index" ref={target}>



          <IndexTitle>Blog</IndexTitle>

          <PostList posts={posts} />

          <header><IndexSubtitle><Link to="/blog">Older Posts</Link></IndexSubtitle></header>


        </main>
      </div>
      <Footer></Footer>
      </motion.div>
    </div>
  )
}

export default Index

const SmallGrid = styled.ul`
  list-style: none;
  display: grid;
  padding: 0;
  justify-items: center;
  grid-gap: var(--size-600);
  grid-template-columns: repeat(auto-fit,minmax(23ch,1fr));
  margin: calc(var(--spacing) * 2) 0;

  li {
    margin: 0;
    padding: 0;
  }
`;

const Workouts = styled.ul`
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  justify-items: left;

`;

const Questions = styled.ul`
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  justify-items: left;

  li {
    line-height: 1.28;
    margin: calc(var(--spacing) * 1) calc(var(--spacing) * 1.5)  ;
    font-size: var(--size-400);
    font-weight: 300;
    color: var(--dark);
    text-shadow: .2px .2px 0 var(--primaryRGB 0.5);
  }
`;

const Workout = styled.div`
  line-height: 1.28;
  margin: calc(var(--spacing) * 1) 0 0 0;
  font-size: var(--size-400);
  font-weight: 300;
  color: var(--dark);
  text-shadow: .2px .2px 0 var(--primaryRGB 0.5);
`;

const Pace = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: var(--h6);
  color: var(--primary);
  font-weight: 300;
`;

const IndexTitle = styled.h2`
  margin-top: 0rem;
  text-align: center;
    & a {
      text-decoration: underline;
      color: var(--secondary);
      text-shadow: .2px .2px 0 var(--darkRGB 0.5);
      font-weight: 600;
    }
`;

const IndexSubtitle = styled.h4`
  margin: .5rem;
  text-align: center;
    & a {
      text-decoration: underline;
      color: var(--secondary);
      text-shadow: .2px .2px 0 var(--darkRGB 0.5);
      font-weight: 600;
    }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 6, sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {type: {ne: "about"}, author: {eq: "Maureen"}}}) {
      nodes {
        excerpt(pruneLength: 200)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          path
        }
      }
    }
  }
`
